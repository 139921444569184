<template>
    <form-row>
        <form-input
            id="address-raw"
            v-slot="{ focused, onBlur }"
            v-model="addressRaw"
            label="Address"
            type="text"
            v-bind="addressRawAttrs"
            autocomplete="new-password"
            disable-password-manager
            :required="props.required"
            :icon="['fad', 'location-dot']"
            :loading="status === 'pending'"
            :errors="props.errors"
        >
            <div
                v-if="focused && addresses.length > 0"
                class="absolute z-50 top-full mt-1.5 inset-x-0 bg-gray-100 border-gray-200 border-2 rounded-md shadow-2xl dark:bg-gray-800 dark:border-gray-700 dark:text-white"
            >
                <ul>
                    <li
                        v-for="item in addresses"
                        :key="item.id"
                    >
                        <button
                            type="button"
                            class="block w-full text-left px-4 py-2"
                            @click.prevent="(event) => {
                                onSelect(event, item);
                                onBlur(event);
                            }"
                        >
                            {{ item.raw }}
                        </button>
                    </li>
                </ul>
            </div>
        </form-input>

        <form-field
            v-if="!address?.latitude || !address?.longitude"
            shrink
        >
            <styled-button
                class="mt-6 min-h-[44px]"
                @click.prevent="onChooseLocation"
            >
                Choose Location on Map
            </styled-button>
        </form-field>
    </form-row>

    <form-row v-if="address?.latitude && address?.longitude">
        <form-field>
            <div id="map">
                <lazy-mapbox-map
                    :center="[address.longitude, address.latitude]"
                    :zoom="12"
                />
            </div>
        </form-field>
    </form-row>

    <modal
        v-if="showLocationModal"
        title="Choose Location"
    >
        <form-row>
            <form-field>
                <label class="form-label">Please click a location on the map.</label>
                <div class="aspect-square">
                    <lazy-mapbox-map
                        :center="[146.610004, -42.040398]"
                        :center-pin="false"
                        :zoom="7"
                        @choose="(event: any) => {
                            addressLat = event.lngLat.lat;
                            addressLng = event.lngLat.lng;
                            showLocationModal = false;
                        }"
                    />
                </div>
            </form-field>
        </form-row>
    </modal>
</template>

<script setup lang="ts">
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faCirclePlus, faLocationDot } from '@fortawesome/pro-duotone-svg-icons';

    library.add(faCirclePlus, faLocationDot);

    export interface Props {
        errors?: string[] | string;
        required?: boolean;
    }

    const props = withDefaults(defineProps<Props>(), {
        errors: undefined,
        required: false,
    });

    const runtimeConfig = useRuntimeConfig();
    const defineField = inject('defineField');

    // @ts-expect-error
    const [ address ] = defineField('address');

    // @ts-expect-error
    const [ addressRaw, addressRawAttrs ] = defineField('address.raw');

    // @ts-expect-error
    const [ addressLat ] = defineField('address.latitude');

    // @ts-expect-error
    const [ addressLng ] = defineField('address.longitude');

    const freeze = ref<boolean>(false);
    const showLocationModal = ref<boolean>(false);

    const { data, status } = await useAsyncData(
        'addresses',
        // @ts-ignore
        () => $fetch('https://places.googleapis.com/v1/places:autocomplete', {
            method: 'POST',
            headers: {
                'X-Goog-Api-Key': runtimeConfig.public.googleApiKey as string,
            },
            body: {
                input: addressRaw.value,
                includedRegionCodes: [ 'au' ],
            },
        }), {
            lazy: true,
            immediate: false,
            watch: [ addressRaw ],
        }
    );

    const addresses = computed(() => {
        // @ts-expect-error features is not defined on data
        return data.value?.suggestions.map(({ placePrediction }) => {
            return {
                id: placePrediction.placeId,
                raw: placePrediction.text.text,
            };
        }) || [];
    });

    watch(addressRaw, newValue => {
        if (freeze.value) {
            return;
        }

        address.value = { raw: newValue };
    });

    const onSelect = async(event: MouseEvent, item: object) => {
        // @ts-expect-error
        const { id, raw } = item;

        const { addressComponents, location } = await $fetch<{ addressComponents: any, location: any }>(`https://places.googleapis.com/v1/places/${id}`, {
            headers: {
                'X-Goog-Api-Key': runtimeConfig.public.googleApiKey as string,
                'X-Goog-FieldMask': 'addressComponents,location',
            },
        });

        const namedComponents = addressComponents.map((component: any) => {
            const [ type ] = component.types;

            if (type === 'administrative_area_level_1') {
                return {
                    state: component.longText,
                    stateCode: component.shortText,
                };
            }

            if (type === 'country') {
                return {
                    country: component.longText,
                    countryCode: component.shortText,
                };
            }

            return {
                [type]: component.longText,
            };
        }).reduce((acc: any, cur: any) => {
            return { ...acc, ...cur };
        }, {});

        freeze.value = true;

        address.value = {
            raw,
            streetNumber: namedComponents.street_number,
            route: namedComponents.route,
            locality: namedComponents.locality,
            postalCode: namedComponents.postal_code,
            state: namedComponents.state,
            stateCode: namedComponents.stateCode,
            country: namedComponents.country,
            countryCode: namedComponents.countryCode,
            latitude: location.latitude,
            longitude: location.longitude,
        };

        await nextTick();
        freeze.value = false;
    };

    const onChooseLocation = () => {
        showLocationModal.value = true;
    };
</script>

<style lang="postcss" scoped>
    #map {
        @apply block bg-gray-700 rounded-lg;

        aspect-ratio: 1;

        @screen md {
            aspect-ratio: 2 / 1;
        }

        @screen xl {
            aspect-ratio: 3 / 1;
        }
    }
</style>
